(function ($) {
  Drupal.behaviors.mppSortV2 = {
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      var $options = $('.js-mpp-sort__options', context);
      var sortFns = {
        price_low_to_high: function (prod) {
          if (prod) {
            return prod?.defaultSku?.PRICE;
          }
        },
        price_high_to_low: function (prod) {
          if (prod) {
            return prod?.defaultSku?.PRICE * -1;
          }
        },
        display_order: 'displayOrder',
        name_a_z: 'PROD_RGN_NAME',
        reviews: 'AVERAGE_RATING',
        newest: 'AVERAGE_RATING'
      };

      $options.selectBox();
      $options.on('change', function (e) {
        e.preventDefault();
        var $sortMenu = $(this);
        var val = $sortMenu.val();
        var sortFn = sortFns[val];

        $(document).trigger('selectBox-selected', [sortFn]);
      });
    },
    attached: false
  };
})(jQuery);
